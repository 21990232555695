.leaflet-container {
  width: 100%;
  height: 100%;
  min-height: 300px; /* Add a minimum height */
  position: relative;
}

/* Fix for marker icons not displaying correctly */
.leaflet-marker-icon,
.leaflet-marker-shadow {
  max-width: none !important;
  max-height: none !important;
}

.custom-service-icon {
  background: none;
  border: none;
}
